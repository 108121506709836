export default defineNuxtRouteMiddleware(async (to) => {
  // http://example?langcode=fr&customer_id=1234&currencycode=DKK&currencyrate=7.606803&shop=example.myshopify.com
  const { shop, customer_id, langcode, currencycode, currencyrate } = to.query

  const { setIsEmbeddedStore, fetchLocales } = useStoreStore()
  const { authenticateFromStore, logout } = useStoreAuth()

  logout()

  const company = await useStoreStore().fetchStoreFromName(shop as string)
  useStoreStore().langCode = langcode as string
  useStoreStore().currencyCodeExtension = (currencycode as string) || null
  useStoreStore().currencyRate = !isNaN(parseFloat((currencyrate as string) ?? ''))
    ? parseFloat((currencyrate as string) ?? '')
    : 1.0

  useStoreApp().isAppEmbedded = true

  if (company) {
    const companyId = company.company_id
    useStoreApp().companyId = companyId
    setIsEmbeddedStore(companyId, true)

    useStoreCompany().fetchCompany(companyId)
    await fetchLocales(companyId)

    if (customer_id && customer_id !== '' && customer_id !== 'undefined') {
      await authenticateFromStore(companyId, customer_id as string)
    }
  } else {
    return navigateTo('https://www.loyoly.io', { external: true })
  }
})
